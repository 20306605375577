@import "./../../color.scss";
@import "./../../theme/theme";

.notification {
  padding: 18px 20px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include theme() {
    background-color: $white-bg;
    box-shadow: $box_shadow;
  }
  &.none {
    display: none;
  }
  .n_icon_text {
    display: flex;
    align-items: center;
    font-size: 0.85rem;
    font-weight: 600;
    @include theme() {
      color: theme-get("$text-color") !important;
    }

    .n_icon {
      display: flex;
      align-items: center;
      margin-right: 15px;
      svg {
        font-size: 20px;
      }
    }
  }
  .n_close {
    display: flex;
    align-items: center;
    opacity: 0.5;
    padding: 3px;
    border-radius: 3px;
    cursor: pointer;
    transition: all ease-in-out 600ms;
    @include theme() {
      color: theme-get("$text-color") !important;
    }
    &:hover {
      opacity: 1;
      @include theme() {
        background: theme-get("$overlay") !important;
      }
    }
    svg {
      font-size: 16px;
    }
  }
}
