.user-detail {
  display: flex;
  justify-content: start;
  align-items: center;
  overflow: hidden;

  .avatar {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    margin-right: 10px !important;
    font-weight: 600 !important;
  }

  .user-info {
    display: block;
    overflow: hidden;

    .skeleton-name {
      height: 14px;
      width: 100px;
    }

    .skeleton-email {
      height: 12px;
      width: 120px;
    }

    .user-name {
      font-weight: 700;
      font-size: 14px;
      line-height: 130%;
      color: #000000;
      text-transform: capitalize;
      text-align: left;
      cursor: pointer;
      width: fit-content;
    }

    .user-email {
      font-weight: 500;
      font-size: 12px;
      line-height: 130%;
      color: #000000;
    }

    .ellipsis-on {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
    }
  }
}
