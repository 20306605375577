@import './../color.scss';


// map
$themes: ( //outter map
  darkmode: ( //inner map (dark mode map) 
    '$header-color':$header-color-dark,
    '$border-color':$border-color-dark,
    '$border-light':$border-light-dark,
    '$sidebar-bg': $sidebar-bg-dark,
    '$text-color': $text-color-dark,
    '$text-hovercolor':$text-hovercolor-dark,
    '$hover-bg': $hover-bg-dark,
    '$white-bg': $white-bg-dark,
    '$sidebar-background':$sidebar-background-dark,
    '$grey_bg': $grey_bg-dark,
    '$primary':$primary-dark,
    '$primaryopi': $primaryopi-dark,
    '$primary-color':$primary-color-dark,
    '$primary-bg-op': $primary-bg-op-dark,
    '$overlay':$overlay-dark,
    '$blue-color': $blue-color-dark,
    '$blue-bg-op': $blue-bg-op-dark,
    '$sucess-bg':$sucess-bg-dark,
    '$sucess-opc': $sucess-opc-dark,
    '$danger-bg': $danger-bg-dark,
    '$danger-ops': $danger-ops-dark,
    '$box_shadow':$box_shadow-dark,
    '$switch-high':$switch-high-dark,
    '$swicth_light':$swicth_light-dark,
    '$swtich-text-color':$swtich-text-color-dark,
    '$selection':$selection-dark,
    '$active':$active-dark,
    '$dropdown-bg':$dropdown-bg-dark,
    '$both-white':$both-white-dark,
    '$hover':$hover,
  ),
  lightmode: ( //inner map (light mode map) 
    '$header-color':$header-color,
    '$border-color':$border-color,
    '$border-light':$border-light,
    '$sidebar-bg': $sidebar-bg,
    '$text-color': $text-color,
    '$text-hovercolor':$text-hovercolor,
    '$hover-bg': $hover-bg,
    '$white-bg': $white-bg,
    '$sidebar-background':$sidebar-background,
    '$grey_bg': $grey_bg,
    '$primary':$primary,
    '$primaryopi': $primaryopi,
    '$primary-color':$primary-color,
    '$primary-bg-op': $primary-bg-op,
    '$overlay':$overlay,
    '$blue-color': $blue-color,
    '$blue-bg-op': $blue-bg-op,
    '$sucess-bg':$sucess-bg,
    '$sucess-opc': $sucess-opc,
    '$danger-bg': $danger-bg,
    '$danger-ops': $danger-ops,
    '$box_shadow':$box_shadow,
    '$switch-high':$switch-high,
    '$swicth_light':$swicth_light,
    '$swtich-text-color':$swtich-text-color,
    '$selection':$selection,
    '$active':$active,
    '$dropdown-bg':$dropdown-bg,
    '$both-white':$both-white,
    '$hover':$hover,
  ),
);

$theme-map: null;

// mixin
@mixin theme() {
  @each $theme, $map in $themes { //iterrates on themes
    $theme-map: $map !global; // not global variable
    .#{$theme} & { //outputs mode selector
      @content;  // apply styles from .content
    }
  }
//   $theme-map: null !global; // eventually null $theme-map
}

@function theme-get($key) { //helper function 
  //outputs value of specific key inside $theme-map (its name)
  @return map-get($theme-map, $key); 
}


// .content{
//     padding: 0px;
//     @include theme() {
//         .header{
//             border: 1px solid #f00;
//             background-color:theme-get('$white-bg');
//         }  
//     }
// }

