@import "./../../color.scss";

.filter_all {
  border-radius: 6px;
  border: 0px solid $border-color;
  background-color: transparent;
  width: 100%;
  margin: 10px 0;
  transition: all 500ms ease-in-out;

  .ant-select-selector,
  .ant-picker {
    box-shadow: $box_shadow;
  }

  .filter {
    .filter_data {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .ant-picker {
        border-radius: 5px;
        padding: 0;
        .ant-picker-input {
          padding: 5px 15px;
          input {
            width: 90px;
            &::placeholder {
              color: $text-color;
              opacity: 0.5;
            }
          }
        }
        &.cal_active {
          border-color: $primaryopi;
          .ant-picker-input {
            background-color: $primaryopi;
            padding: 5px 15px;
            input {
              color: $primary;
              font-weight: 700;
            }
            .ant-picker-clear {
              background-color: $primaryopi;
              color: $primary;
              right: 15px;
              top: 17px;
            }
            .ant-picker-suffix {
              color: $primary;
            }
          }
        }
      }
      .ant-picker-range {
        padding-right: 15px;
        input {
          width: 80px;
        }
        .ant-picker-active-bar {
          background-color: $primary !important;
        }
        .ant-picker-clear {
          background-color: $primaryopi;
          color: $primary;
          right: 15px;
          top: 17px;
        }
        &.rang_active {
          background-color: $primaryopi !important;
          input {
            color: $primary;
            font-weight: 700;
          }
          .ant-picker-suffix,
          .ant-picker-range-separator {
            color: $primary;
          }
        }
      }

      .ant-select-multiple {
        padding: 0;
        .ant-select-selection-search {
          margin-inline-start: 0px !important;
        }
        .ant-select-selector {
          padding: 2px 5px !important;
        }
        .ant-select-selection-placeholder {
          padding: 0 !important;
        }
      }

      .ant-select {
        border-radius: 5px;
        .ant-select-selection-placeholder {
          color: $text-color;
          opacity: 0.9;
        }
        .ant-select-clear {
          background-color: $primaryopi;
          color: $primary;
        }
        .ant-select-selector {
          padding: 0;
        }
        .ant-select-selection-placeholder {
          padding: 0 10px;
        }
        .ant-select-selection-item {
          // background-color: $primaryopi;
          // color: $primary;
          // font-weight: 700;
          padding: 0 10px;
        }
      }
      .f_start {
        display: flex;
        align-items: center;
        padding: 8px 8px 8px 0;

        .ant-select {
          margin-left: 10px;
          &:first-child {
            margin-left: 0;
          }
        }
      }
      .f_end {
        display: flex;
        align-items: center;
        padding: 0px;
        .ant-picker {
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
    .filter_result {
      padding: 8px;
      border-top: 1px solid $border-color;
      .ant-typography {
        font-size: 0.75rem;
        color: $text-color;
        opacity: 0.5;
      }
      .f_resultblock {
        display: flex;
        align-items: center;
        .f_batch {
          display: flex;
          align-items: center;
          border: 1px solid $border-color;
          border-radius: 5px;
          overflow: hidden;
          background-color: $sidebar-bg;
          margin: 0 5px;
          &:first-child {
            margin-left: 0;
          }
          .f_label {
            font-size: 0.75rem;
            padding: 4px 8px;
            font-weight: 600;
            background-color: $grey_bg;
            text-transform: uppercase;
            letter-spacing: 0.3px;
            border-right: 1px solid $border-color;
          }
          .f_val {
            padding: 2px 8px;
            font-size: 0.8rem;
            font-weight: 600;
            background-color: $sidebar-bg;
          }
          .f_close {
            margin: 2px;
            background-color: $grey_bg;
            padding: 1px 7px;
            cursor: pointer;
            border-radius: 2px;
            transition: all 500ms ease-in-out;
            &:hover {
              background-color: $danger-ops;
              svg {
                color: $danger-bg;
              }
            }
            svg {
              font-size: 0.7rem;
              transition: all 500ms ease-in-out;
            }
          }
        }
      }
    }
  }
}

.sort_by {
  display: flex;
  align-items: center;
  svg {
    margin-left: 10px;
  }
}
.sort_b {
  display: flex;
  align-items: center;
  background-color: $white-bg;
  border-radius: 5px;
  padding: 6px 15px;
  .s_b {
    font-size: 14px;
    svg {
      margin-left: 10px;
    }
    span {
      svg {
        margin: 0;
      }
    }
  }
}

.sort_dropdown {
  li {
    transition: all ease-in-out 600ms;
    .sort_n {
      font-size: 0.85rem;
    }
    &:hover {
      background-color: $hover !important;
      border-radius: 0 !important;
      &:after {
        content: "";
        position: absolute;
        left: 0;
        background-color: $primary;
        width: 3px;
        height: 100%;
      }
    }
  }
}
.d_filter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 11.5px;
  font-weight: 600;
  .d_hover {
    transition: all ease-in-out 600ms;
    cursor: pointer;
    padding: 5px 10px 3px;
    margin-bottom: 5px;
    border-radius: 4px;
    &:hover {
      color: $primary;
      background-color: $primaryopi;
    }
  }
}

.highlight {
  color: $primary;
  transform: scale(1.5);
}

.multi-user-select-filter {
  .ant-select-disabled {
    opacity: 50%;
  }

  .ant-select-selection-item {
    display: flex;
    align-items: center;
    height: 28px;
    border: none;
    background: transparent;
    margin: 0;
  }
}

.ant-select-disabled {
  opacity: 70%;
}
