@import "./../../color.scss";
@import "../../theme/theme";

.att_profile {
  @include theme() {
    background-color: theme-get("$sidebar-bg");
    border-color: theme-get("$border-color");
  }

  background-color: $sidebar-bg;
  border: 1px solid;
  padding: 20px;
  margin: 0px 0 20px;
  border-radius: 8px;

  .a_p_block {
    display: flex;
    align-items: center;

    .ant-avatar {
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 800;
      margin-right: 15px;
    }

    .at_block {
      display: flex;
      flex-direction: column;
    }

    .a_text {
      font-size: 0.89rem;
      font-weight: 700;
    }

    .a_mail {
      font-size: 0.75rem;
      font-weight: 600;
      opacity: 0.6;
    }
  }
}

.punchlog {
  font-size: 0.8rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  &.head {
    font-weight: 700;
    padding: 15px 24px;
    padding-left: 35px;
    padding-right: 45px;
    border-bottom: 1px solid $border-color;
  }

  .pin {
    svg {
      color: $sucess-bg;
      margin-right: 2px;
    }
  }

  .pout {
    svg {
      color: $danger-bg;
      margin-right: 2px;
    }
  }
}

.cal_legend {
  margin: 05px 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  li {
    list-style: none;
    padding: 7px 20px 5px 15px;
    margin: 0 10px;
    position: relative;

    &::before {
      position: absolute;
      left: 0;
      content: "";
      width: 11px;
      height: 11px;
      border-radius: 50%;
      top: 11px;
    }

    &.c_p::before {
      background-color: $sucess-bg;
    }

    &.c_a::before {
      background-color: $danger-bg;
    }
    &.c_m_p::before {
      background-color: $min-presence-bg;
    }

    &.c_a_n::before {
      background-color: $absent-new-bg;
    }
    &.c_f::before {
      background-color: $full-new-bg;
    }

    &.c_h::before {
      background-color: $half-new-bg;
    }
    &.c_w::before {
      background-color: $weekoff-bg;
    }
  }
}

.column_tooltip {
  display: flex;
  align-items: center;
  transition: all ease-in-out 600ms;
  position: relative;
  cursor: pointer;

  &:hover {
    // background-color:  $primaryopi;
    // &::before{ content: ''; position: absolute;  display: flex;}
  }

  .info_i {
    margin: 0 0px 0 10px;
    display: flex;
    opacity: 0.6;
    transition: all ease-in-out 600ms;
    border-radius: 50%;

    &:hover {
      opacity: 1;
      padding: 0px;
    }
  }
}

.ant-avatar-string {
  position: relative !important;
  left: unset !important;
  transform: unset !important;
}

.avt_name {
  width: calc(100% - 35px);
  text-transform: capitalize;
}

.prsence_block {
  display: flex;
  flex-direction: column;

  .p_header {
    padding: 10px 20px;
    text-transform: capitalize;
    font-weight: 700;

    &.a_present {
      background-color: $sucess-opc;
      color: $sucess-bg;
      border-bottom: 1px solid $sucess-bg;
    }

    &.a_absent {
      background-color: $danger-ops;
      color: $danger-bg;
      border-bottom: 1px solid $danger-bg;
    }
  }

  .pa_box {
    max-height: calc(100vh - 250px);
    overflow-x: hidden;
  }

  .user_block {
    padding: 10px 20px;
    border-bottom: 1px solid $border-light;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .user_box {
      display: flex;
      align-items: center;

      .av_name {
        margin-left: 10px;
        font-weight: 700;
        text-transform: capitalize;
      }

      .pre {
        padding: 0 20px;
        display: flex;
        align-items: center;

        svg {
          font-size: 14px;
          fill: $sucess-bg;
          margin-right: 10px;
        }
      }

      .abs {
        padding: 0 20px;
        display: flex;
        align-items: center;

        svg {
          font-size: 14px;
          font-weight: 800;
          fill: $danger-bg;
          margin-right: 10px;
        }
      }
    }
  }
}

.apexcharts-grid-borders {
  line {
    &:nth-child(2) {
      opacity: 0;
      stroke: #7b61ff !important;
    }
  }
}
.pin {
  display: flex;
  margin-right: 2px;
}
.pout {
  display: flex;
}
