@import "./../../color.scss";

.upgrad_block {
  .ant-btn {
    justify-content: center;
  }
}

.up_b {
  .ant-segmented {
    display: flex;
    padding: 4px;
    align-items: flex-start;
    gap: 2px;
    border-radius: 6px;
    background: #cfd8dc;
    // background-color: #cfd8dc; //$swicth_light;
    // color: #191127; //$swtich-text-color;

    .ant-segmented-item-selected {
      color: #191127; //$swtich-text-color;
      font-weight: 600;
    }
  }
  .ant-segmented-item-selected {
    color: #191127; // var(--grey-grey-700-graph-axis-text, #86909C);
    // font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    // line-height: normal;
  }

  .ant-segmented-item-label {
    // color: #86909c;
    // font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
  }
}

.zoho-hostedpage-modal {
  top: 20px;
  .ant-modal-content {
    padding: 0;
    overflow: hidden;
  }
}
