@import "./../../color.scss";
@import "../../theme/theme";

// top header
.header {
  @include theme() {
    background-color: theme-get("#F5F6F9"); //theme-get("$white-bg");
    min-height: 56px;
    // border-bottom: 1px solid $border-color;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 999;

    // width: 100vw;
    .logo_section {
      display: flex;
      align-items: center;
      // max-width: 190px;
    }

    .logo {
      img {
        max-width: 75%;
      }
    }

    .h_icon {
      svg {
        font-size: 18px;
      }
    }
  }
}

// dropdown profile
.ant-dropdown-menu {
  padding: 0px !important;
  border-bottom: 1px solid $border-color;

  .ant-dropdown-menu-item-divider {
    margin: 2px 0 !important;
  }

  .icon_p {
    display: flex;
    align-items: center;
    font-size: 0.85rem;
    text-transform: capitalize;
    padding: 1px 0;
    color: $text-color;

    svg {
      width: 15px;
      height: 15px;
      margin: 0 10px 0 0px;
    }
  }

  .profile_avtar {
    display: flex;
    align-items: center;
    margin: 10px 0;

    // padding-bottom: 10px;
    .ant-avatar {
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 0.85rem;
      font-weight: 700;
    }

    .p_detial {
      display: flex;
      flex-direction: column;
      margin-left: 15px;

      a {
        color: $text-color !important;
      }

      .text_profile {
        font-weight: 600;
      }

      .email_profile {
        font-size: 0.78rem;
        opacity: 0.6;
      }
    }
  }

  .p_icon_text {
    display: flex;
    align-items: center;
    font-size: 0.85rem;
    padding: 1px 0;
    color: $text-color;
    opacity: 0.7;

    &:hover {
      opacity: 1;
      font-weight: 600;
    }

    svg {
      width: 22px;
      height: 20px;
      margin: 0 22px 0 10px;
    }
  }

  .dark-mode {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 0.85rem;
    padding: 1px 0;
    color: $text-color;
    opacity: 0.7;

    &:hover {
      opacity: 1;
      font-weight: 600;
    }

    svg {
      width: 22px;
      height: 20px;
      margin: 0 22px 0 10px;
    }
  }
}

// alert
.a_heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid $border-color;
  padding: 8px 0 10px;

  h2 {
    font-size: 0.85rem;
    margin: 0px;
    font-weight: 700;
  }

  .mark_asread {
    font-size: 0.75rem;
    font-weight: 800;
    display: flex;
    align-items: center;
  }
}

.alert_block {
  display: flex;
  align-items: center;
  max-width: 350px;
  min-width: 250px;
  padding: 8px 8px;

  &:hover {
    background-color: $hover-bg;

    .a_text {
      color: $primary-color;
    }
  }

  .al_flex {
    display: flex;
    flex-direction: column;
  }

  .a_text {
    font-size: 0.85rem;
    font-weight: 600;
  }

  .a_date_time {
    font-size: 0.75rem;
    margin-top: 5px;
    font-weight: 600;
    opacity: 0.6;
  }

  .a_date_time span {
    color: $text-color;

    &::before {
      content: "at";
      margin-right: 5px;
    }
  }

  .a_name {
    font-size: 0.75rem;
    margin-top: 5px;
    font-weight: 800;
    opacity: 0.6;
    position: relative;
    margin-right: 20px;
    text-transform: capitalize;

    &::after {
      content: "";
      margin: 0 8px;
      width: 6px;
      height: 6px;
      top: 7px;
      border-radius: 50%;
      background-color: $text-color;
      position: absolute;
    }
  }

  .a_icon {
    margin-left: 20px;
    display: flex;

    align-items: center;
  }
}

.viewall {
  a {
    font-size: 0.8rem;
    margin: 0px;
    font-weight: 700;
  }
}

// quick start
.quickstart {
  display: flex;
  align-items: center;
  padding: 5px 10px;
  border-radius: 3px;

  &:hover {
    background-color: rgba(120, 166, 252, 0.253);
    cursor: pointer;
  }

  .q_block {
    display: flex;
    flex-direction: column;
    margin-left: 10px;

    .q_text {
      font-size: 0.9rem;
      font-weight: 700;
    }

    .q_still_t {
      span {
        margin-right: 5px;
      }
    }
  }

  .ant-progress-circle-path {
    stroke: $primary !important;
  }
}

// active_quick_block
.active_quick_block {
  position: fixed;
  top: 56px;
  width: 450px;
  height: calc(100vh - 56px);
  z-index: 10;
  // border-left: 1px solid $border-color;
  box-shadow: -8px 0px 15px -10px rgba(0, 0, 0, 0.25);
  background-color: $white-bg;
  right: 0;
  padding: 15px;

  .close {
    cursor: pointer;
    padding: 5px;
  }

  .modal_heading {
    display: flex;
    align-items: center;
    justify-content: space-between;

    h2 {
      font-size: 1.2rem;
      font-weight: 800;
      margin: 0;
    }
  }

  p {
    font-size: 0.9rem;
    font-weight: 600;
    margin: 15px 0 10px;
  }

  .number {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 50px 0 40px;

    .p_img {
      width: 42%;
      text-align: center;

      img {
        max-width: 100%;
      }
    }

    .process {
      display: flex;
      flex-direction: column;
      width: 52%;

      .ant-progress-text {
        font-weight: 800;
      }

      .ant-progress {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .ant-progress-circle-path {
        stroke: $primary;
      }

      .still_t {
        margin-top: 15px;
        font-size: 0.85rem;
        font-weight: 700;
        text-align: center;
      }
    }
  }

  .what_next {
    margin-bottom: 20px;

    h2 {
      font-size: 0.9rem;
      font-weight: 600;
      margin: 0;
    }

    p {
      margin: 0;
    }

    .step_block {
      border-radius: 8px;
      border: 1px solid $border-color;
      padding: 15px 20px;
      background-color: $white-bg;
      font-size: 0.85rem;
      margin: 15px 0;
      cursor: pointer;
      transition: all 500ms ease-in-out;
      color: $primary;

      &:hover {
        border: 1px solid $primary;
        background-color: $primaryopi;
      }

      .s_heading {
        font-size: 0.95rem;
        font-weight: 700;
        margin: 0 0 5px;
      }
    }

    .completed_block {
      border-radius: 8px;
      border: 1px solid $border-color;
      padding: 15px 20px;
      background-color: $white-bg;
      font-size: 0.85rem;
      margin: 15px 0;
      cursor: pointer;
      transition: all 0.05 ease-in-out;
      display: flex;
      align-items: center;
      transition: all 500ms ease-in-out;

      &:hover {
        border: 1px solid $sucess-bg;
        background-color: $sucess-opc;

        svg,
        .s_heading {
          color: $sucess-bg;
        }
      }

      svg {
        margin-right: 15px;
        font-size: 24px;
        transition: all 500ms ease-in-out;
      }

      .s_heading {
        font-size: 0.95rem;
        font-weight: 700;
        margin: 0px;
        transition: all 500ms ease-in-out;
      }
    }
  }
}

.ant-drawer .ant-drawer-mask {
  background-color: transparent !important;
}

.delete_box {
  .s_text {
    color: $danger-bg;
  }

  .s_des {
    color: $danger-bg;
  }
}

.info {
  position: absolute;
  top: -27px;
  padding: 2px;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 0;
  opacity: 0.6;
  transition: all ease-in-out 600ms;
  cursor: pointer;

  &:hover {
    opacity: 1;
    color: $text-hovercolor;
    background-color: $primaryopi;
  }
}

.account_avtar {
  padding: 5px;
  margin: -5px -8px;
  border-radius: 5px;
  transition: all ease-in-out 600ms;

  &:hover {
    background-color: $primaryopi;
  }

  a {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $text-color;

    &:hover {
      color: $primary;
      text-decoration: none;
    }

    svg {
      width: 22px;
      opacity: 0.6;
    }
  }

  .a_block {
    display: flex;
    align-items: center;

    .ant-avatar {
      border-radius: 5px;
      font-weight: 700;
      font-size: 0.85rem;
      margin: 0 18px 0 8px;
    }

    p {
      font-weight: 700;

      .badge {
        padding: 5px 10px;
        border-radius: 3px;
        margin-left: 10px;
        font-size: 0.75rem;
        font-weight: 600;
        text-transform: capitalize;

        &.b_owner {
          background-color: $overlay;
          color: $text-color;
        }
      }
    }
  }
}

.profileinfo {
  display: flex;
  justify-content: flex-start;
  column-gap: 10px;
  width: 100%;

  .p_pic {
    display: flex;
    flex-direction: column;
    width: 110px;

    .ant-upload-list-item-actions {
      display: none;
    }

    .at_upload {
      position: relative;
      z-index: 5;

      &::before {
        transition: all ease-in-out 600ms;
      }

      &:hover {
        &::before {
          content: "upload";
          background-color: #ffffffb0;
          left: 0px;
          z-index: 5;
          display: flex;
          align-items: flex-end;
          justify-content: center;
          position: absolute;
          bottom: 0px;
          width: calc(100% - 0px);
          height: calc(100% - 0px);
        }
      }
    }
  }

  .p_info {
    padding: 5px;
    width: 100%;
  }
}

.ant-descriptions-item-label {
  opacity: 0.6;
}

.org {
  background-color: $primaryopi;
  border-color: $primaryopi;
}

.h-freetrail {
  .ant-progress-text {
    .days {
      font-size: 12px;
      font-weight: 800;

      span {
        font-weight: 400;
        color: #a1a1a1;
        font-size: 10px;
      }
    }
  }
}

.serach-common {
  .ant-select-selector {
    height: 38px !important;
  }

  .ant-input-group .ant-input-group-addon .ant-select {
    margin: 0px -11px Im !important;
  }
  .ant-select-selection-item {
    font-size: 14px;
  }
}
.ant-divider-horizontal {
  clear: both;
  width: 100%;
  min-width: 100%;
  margin: 2px 0;
  display: flex;
}
